// 这是数说企业-国企结构面板

import DataStoriesBoard1SoesController from "./soes_controller"; // board1 - 国有企业数据
import DataStoriesBoard1SoeHoldingListedCompaniesController from "./soe_holding_listed_companies_controller";  // board1 - 属国资控股上市公司
import DataStoriesBoard1CentralSoesController from "./central_soes_controller";  // board1 - 中央企业
import DataStoriesBoard1SoeTransRegionalInvestmentCompaniesController from "./soe_trans_regional_investment_companies_controller";  // board1 - 地方国有跨地区投资企业

function setup(application){
  application.register("data-stories--board1--soes", DataStoriesBoard1SoesController)
  application.register("data-stories--board1--soe-holding-listed-companies", DataStoriesBoard1SoeHoldingListedCompaniesController)
  application.register("data-stories--board1--central-soes", DataStoriesBoard1CentralSoesController)
  application.register("data-stories--board1--soe-trans-regional-investment-companies", DataStoriesBoard1SoeTransRegionalInvestmentCompaniesController)
}

export {
  setup
}
