import { Controller } from "@hotwired/stimulus";
import * as echarts from 'echarts';

export default class extends Controller {
  connect() {
    const options = {
      yAxis: {
        type: 'category',
        data: ['采购', '车辆', '房产', '废旧物资','设备','土地','无形资产','知识'],
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: '#18818B',
            width: 2
          }
        },
        axisLabel: {
          color: '#8DA7B3'
        }
      },
      xAxis: {
        position: 'top',
        type: 'value',
        name: '金额(万元)',
        nameTextStyle: {
          color: '#8DA7B3'
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: '#18818B',
            width: 2
          }
        },
        axisLabel: {
          color: '#8DA7B3'
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#18818B',
            type: 'dashed'
          }
        }
      },
      series: [
        {
          data: [parseInt((Math.random(100)*100) + 10), parseInt((Math.random(100)*100) + 10), parseInt((Math.random(100)*100) + 10), parseInt((Math.random(100)*100) + 10), parseInt((Math.random(100)*100) + 10), parseInt((Math.random(100)*100) + 10), parseInt((Math.random(100)*100) + 10), parseInt((Math.random(100)*100) + 10)],
          type: 'bar',
          barWidth: '35%',
          color: '#F0982F',
          label: {
            show: true,
            position: 'top',
            color: 'white',
            fontWeight: 'bold',
          },
          itemStyle: {
            normal: {
                //每根柱子颜色设置
                color: function(params) {
                    let colorList = [
                        "#c23531",
                        "#2f4554",
                        "#61a0a8",
                        "#d48265",
                        "#91c7ae",
                        "#749f83",
                        "#ca8622",
                        "#bda29a",
                        "#6e7074",
                        "#546570",
                        "#c4ccd3",
                        "#4BABDE",
                        "#FFDE76",
                        "#E43C59",
                        "#37A2DA"
                    ];
                    return colorList[params.dataIndex];
                }
            }
        }
        }
      ]
    };
    const w = this.element.clientWidth
    const h = this.element.clientHeight
    this.myChart = echarts.init(this.element, null, {
      width: w,
      height: h,
    });
    this.myChart.setOption(options);

    this.myChart.resize({
      width: this.element.clientWidth,
      height: this.element.clientHeight
    });

    window.addEventListener("resize", () => {
      this.myChart.resize({
        width: this.element.clientWidth,
        height: this.element.clientHeight
      });
    })
  }

  disconnect() {
    window.addEventListener("resize", () => {
      this.myChart.resize();
    })
  }
}
