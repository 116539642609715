// 这是数说事件- 交易所在外地的国有产权并购项目和外地交易所参与的国资并购项目

import OverseasExchangeSoprProjectsController from "./overseas_exchange_sopr_projects_controller"; // board3 - XX省交易所在外地的国有产权并购项目
import ForeignExchangeSoprProjectsController from "./foreign_exchange_sopr_projects_controller"; // board3 - xx省交易所在外地的国有产权并购项目
import ExchangeSoprProjectsOtherProvincesController from "./exchange_sopr_projects_other_provinces_controller"; // board3 - xx省交易所在外地各省国有产权并购项目统计
import ForeignExchangeSoprProjectsOtherProvincesController from "./foreign_exchange_sopr_projects_other_provinces_controller"; // board3 - xx省交易所在外地各省国有产权并购项目统计


function setup(application){
  application.register("data-events--board3--overseas-exchange-sopr-projects", OverseasExchangeSoprProjectsController)
  application.register("data-events--board3--foreign-exchange-sopr-projects", ForeignExchangeSoprProjectsController)
  application.register("data-events--board3--exchange-sopr-projects-other-provinces", ExchangeSoprProjectsOtherProvincesController)
  application.register("data-events--board3--foreign-exchange-sopr-projects-other-provinces", ForeignExchangeSoprProjectsOtherProvincesController)
}

export {
  setup
}
