import CheckboxSelectAll from 'stimulus-checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ['checkboxAll','checkbox','button','input']

  connect() {
    super.connect()
    this.updateFilterBar()
  }

  initialize(){
    super.initialize()
    this.checkboxInputListener = this.checkboxInputListener.bind(this)
  }


  checkboxTargetConnected(e){
    e.addEventListener('input', this.checkboxInputListener)
    let checkedValue = e.attributes.checked.value
    if(checkedValue=='false'){
      e.removeAttribute('checked');
    }
  }

  checkboxTargetDisconnected(e){
    e.removeEventListener('input', this.checkboxInputListener)
  }

  checkboxInputListener(e){
    this.updateFilterBar()
  }

  // 全选box的事件
  toggle(e){
    super.toggle(e)
    this.updateFilterBar()
  }

  updateFilterBar(){
    this.inputTarget.setAttribute('value', this.checked.map(item => item.value)) // 选中的参数在这里
    if(this.unchecked.length == 0){ // 全选，按钮可用
      this.checkboxAllTarget.indeterminate = false
      this.checkboxAllTarget.checked = true
      this.buttonTarget.disabled = false
      this.buttonTarget.classList.add('bg-primary-line')
      this.buttonTarget.classList.remove('bg-gray-500')

    } else if (this.checked.length == 0) { // 全不选，按钮不可用
      this.checkboxAllTarget.indeterminate = false
      this.checkboxAllTarget.checked = false
      this.buttonTarget.disabled = true
      this.buttonTarget.classList.remove('bg-primary-line')
      this.buttonTarget.classList.add('bg-gray-500')

    } else { // 半选，按钮可用，全选框不选中
      this.checkboxAllTarget.indeterminate = true
      this.buttonTarget.disabled = false
      this.buttonTarget.classList.add('bg-primary-line')
      this.buttonTarget.classList.remove('bg-gray-500')
    }
  }
}
