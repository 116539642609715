import { Controller } from "@hotwired/stimulus";
import * as echarts from 'echarts';
export default class extends Controller {
  static values = {
    data: Object, title: String
  }

  initialize(){
    this.resize = this.resize.bind(this)
    this.options = {}
  }

  connect() {
    this.element[this.identifier] = this
    this.setOptions()
    this.myChart = echarts.init(this.element, null);
    this.myChart.setOption(this.options);
    this.resize()
    window.addEventListener("resize", this.resize)
  }

  disconnect() {
    this.myChart.dispose()
    delete this.myChart
    window.removeEventListener("resize", this.resize)
  }

  setOptions(){
    console.warn("必须定义 setOptions 为 EChart 设置显示的值")
  }

  resize(){
    if(this.myChart){
      this.myChart.resize(
        {
          width: this.element.clientWidth,
          height: this.element.clientHeight
        }
      )
    }
  }
}
