import ChartController from "../../chart_controller";
import { showFullScreen } from "../../../utils";

export default class extends ChartController {
  setOptions() {
    let legends_obj = Object.values(Object.entries(this.dataValue)[0][1])[0];
    delete legends_obj["挂牌金额"];
    delete legends_obj["成交金额"];
    let legends = Object.keys(legends_obj);
    let xAxis = [];
    let xAxisValue = [];
    for (const key in this.dataValue) {
      xAxis.push(key);
      const indexOfKey = xAxis.indexOf(key);
      xAxisValue[indexOfKey] = this.dataValue[key]["总记"];
    }
    const series = legends.map((legend) => {
      return [legend, xAxis.map((x_axis, index) => xAxisValue[index][legend])];
    });
    let yAxis1 = series[0][1].concat(series[1][1]);
    let yAxis2 = series[2][1].concat(series[3][1]);

    const options = {
      title: {
        show: false,
        text: this.titleValue,
        top: "2%",
        left: "2%",
        textStyle: {
          color: "#fff",
        },
      },
      toolbox: {
        show: true,
        itemSize: 20,
        top: "2%",
        right: "2%",
        feature: {
          myFull: {
            show: true,
            title: "大图",
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAW9JREFUWEftlkFOwzAQRWd8B27QFgn2LV2khAMgVvQQcAjCihPAIdodFyDgRQn7INH2BtxhBhlqybUcRyR2qiK6yaKZ/Odv+88g7PiHO9aHvwVwKItLJjr2uUoC8nUyzvU7QR0YPC8yEOLGu61Et8vTcRYFQDlAgFc2AAIfAMCPMzEBbOHey+uRQJxtxMvvZ1cAtjgDfiJw2gmALU7MU0Rx3wmAS3w9OXnvy7en6ABV4upsRAfwiXcC0JfFIwKcA0Cp9lzZbt4KHVIoRPmRjOZRcmAgixkxZ7a4L5iCJmGTxvYPsF8OqKuGIO5Wk+FFk/121Ww5UHVVVKF1z+fLZDQNAbEFoPs5A+arZHimBepCpg1ILUBMcQXuBYgt7gVgpmtzmHDFaxvrnVFsngFjjHJmewjxSgdUQ9FjVKyVex3Y/FmqMcpeqQB+MLtZWyech/A3Y3VQgJ5cpIIg9bZPq58HBWj7sSb1+9WMmqywruYLOPX+Ibku0ksAAAAASUVORK5CYII=",
            onclick: (e) => {
              showFullScreen(e.getOption());
            },
          },
          myFullBack: {
            show: false,
            title: "缩小",
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAaZJREFUWEfFlbtKA0EUhv/ZFPoMVhZhES3scjWraQQ7G9NZKtgK6WMv2Ap2dpo+rUk2kqx2YoRsIrY+hMyRiRlZ170Edmd2HmC+b/5zzhyGjA/Tzc/3R5szq/QmuVoFBNxg7J4TNaSENgEJB7AFYCwltAiEwUUZlAtEwVMTMPvOIQy24u4U7rxNHQdPT2DgfICwDuKnrlW+ERcvA09PwHZIvpwYNYmjI7rd33BBI59KD5gegQVkvAxcSQKeV/6OWtRnpyqBOZMRu5xYhWZmAgJMhOupVTwLk0grgddFzYM5nF+4u+WWuibsDVswcicArfkhBNad1gp1pQkk2aiplECZgGk7jwAqgQDClWsVz5PAI/+BvD3cM2A8RI4Q0fbEKr0kkYgswUbvqcINEikEHg5en9XKXWUC4mJz8HwA4p0giBaBuYTtiMVy5JfQIhAGFzLKBbxwBjQItAqwW5mEUgE/fFIrtn/KMTqWEtz4ys+q1ffUmzAMLkH53mg/x/CZdAQD/4E4eJLXxi4j3fA/CWQB/1cCIcGAtmy4tOOOLYEOoJ+R+Tr+Bkcm4yHTPcxWAAAAAElFTkSuQmCC",
            onclick: (e) => {
              showFullScreen(e.getOption());
            },
          },
        },
      },
      legend: {
        data: legends,
        bottom: "left",
        left: "7%",
        itemHeight: 12,
        itemWidth: 12,
        icon: "rect",
        textStyle: {
          color: "#8DA7B3",
        },
      },
      dataZoom: [
        {
          show: xAxis.length > 8 ? true : false,
          id: "dataZoomX",
          type: "slider",
          xAxisIndex: [0],
          filterMode: "empty",
          startValue: 0,
          endValue: 7,
          height:8,
          brushSelect: false,
          fillerColor: '#168280',
          borderColor: "#178280",
          handleSize: 0,
          showDataShadow: false, // 是否显示数据阴影 默认auto
          showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
        },
      ],
      xAxis: {
        type: "category",
        data: xAxis,
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: "#18818B",
            width: 2,
          },
        },
        axisLabel: {
          color: "#8DA7B3",
        },
      },
      yAxis: [
        {
          type: "log",
          name: "项目数(宗)",
          min: 1,
          logBase: Math.max(Math.ceil(Math.pow(Math.max(...yAxis1),0.25)), 2),
          nameTextStyle: {
            color: "#8DA7B3",
          },
          axisLine: {
            show: true,
            onZero: true,
            lineStyle: {
              color: "#18818B",
              width: 2,
            },
          },
          axisLabel: {
            color: "#8DA7B3",
            formatter: (params) => {
              return params === 1 ? 0 : params;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#18818B",
              type: "dashed",
            },
          },
        },
        {
          type: "log",
          name: "金额(亿元)",
          min: 1,
          nameTextStyle: {
            color: "#8DA7B3",
          },
          logBase: Math.max(Math.ceil(Math.pow(Math.max(...yAxis2),0.25)), 2),
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8DA7B3",
            },
            formatter: (params) => {
              return params === 1 ? 0 : params;
            },
          },
          axisLine: {
            lineStyle: {
              color: "#18818B",
              width: 1, //这里是为了突出显示加上的
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      series: series.map((item, index) => {
        return {
          name: item[0],
          type: "bar",
          yAxisIndex: index > 1 ? 1 : 0,
          color: this.barColors[index],
          barMinWidth: "15",
          barMaxWidth: "25",
          label: {
            show: true,
            formatter: function (param) {
              return Math.round(parseFloat(param.data - 1) * 10000) / 10000;
            },
            position: "top",
            color: "white",
            fontWeight: "bold",
          },
          data: item[1].map((i) => {
            return i + 1;
          }),
        };
      }),
    };
    this.options = options;
  }

  get barColors() {
    return ["#178280", "#40EBEE", "#F0982F", "#FC5621"];
  }
}
