import ChartController from "../../chart_controller";
import { showFullScreen } from '../../../utils'
export default class extends ChartController {
  setOptions() {
    let seriesData = []
    for (const key in this.dataValue) {
      if (Object.hasOwnProperty.call(this.dataValue, key)) {
        const val = this.dataValue[key]
        seriesData.push({name: key, value: val})
      }
    }
    const options = {
      title: {
        show: false,
        text: this.titleValue,
        top: "2%",
        left: "2%",
        textStyle: {
          color: '#fff'
        }
      },
      toolbox:{
        show: true,
        itemSize: 20,
        top: "2%",
        right: "2%",
        feature: {
          myFull: {
            show: true,
            title: '大图',
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAW9JREFUWEftlkFOwzAQRWd8B27QFgn2LV2khAMgVvQQcAjCihPAIdodFyDgRQn7INH2BtxhBhlqybUcRyR2qiK6yaKZ/Odv+88g7PiHO9aHvwVwKItLJjr2uUoC8nUyzvU7QR0YPC8yEOLGu61Et8vTcRYFQDlAgFc2AAIfAMCPMzEBbOHey+uRQJxtxMvvZ1cAtjgDfiJw2gmALU7MU0Rx3wmAS3w9OXnvy7en6ABV4upsRAfwiXcC0JfFIwKcA0Cp9lzZbt4KHVIoRPmRjOZRcmAgixkxZ7a4L5iCJmGTxvYPsF8OqKuGIO5Wk+FFk/121Ww5UHVVVKF1z+fLZDQNAbEFoPs5A+arZHimBepCpg1ILUBMcQXuBYgt7gVgpmtzmHDFaxvrnVFsngFjjHJmewjxSgdUQ9FjVKyVex3Y/FmqMcpeqQB+MLtZWyech/A3Y3VQgJ5cpIIg9bZPq58HBWj7sSb1+9WMmqywruYLOPX+Ibku0ksAAAAASUVORK5CYII=",
            onclick: (e) => {
              showFullScreen(e.getOption())
            },
          },
          myFullBack: {
            show: false,
            title: '缩小',
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAaZJREFUWEfFlbtKA0EUhv/ZFPoMVhZhES3scjWraQQ7G9NZKtgK6WMv2Ap2dpo+rUk2kqx2YoRsIrY+hMyRiRlZ170Edmd2HmC+b/5zzhyGjA/Tzc/3R5szq/QmuVoFBNxg7J4TNaSENgEJB7AFYCwltAiEwUUZlAtEwVMTMPvOIQy24u4U7rxNHQdPT2DgfICwDuKnrlW+ERcvA09PwHZIvpwYNYmjI7rd33BBI59KD5gegQVkvAxcSQKeV/6OWtRnpyqBOZMRu5xYhWZmAgJMhOupVTwLk0grgddFzYM5nF+4u+WWuibsDVswcicArfkhBNad1gp1pQkk2aiplECZgGk7jwAqgQDClWsVz5PAI/+BvD3cM2A8RI4Q0fbEKr0kkYgswUbvqcINEikEHg5en9XKXWUC4mJz8HwA4p0giBaBuYTtiMVy5JfQIhAGFzLKBbxwBjQItAqwW5mEUgE/fFIrtn/KMTqWEtz4ys+q1ffUmzAMLkH53mg/x/CZdAQD/4E4eJLXxi4j3fA/CWQB/1cCIcGAtmy4tOOOLYEOoJ+R+Tr+Bkcm4yHTPcxWAAAAAElFTkSuQmCC",
            onclick: (e) => {
              showFullScreen(e.getOption())
            },
          }
        }
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: '7%',
        show: false,
        textStyle: {
          color: '#8DA7B3'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          color: [ "#1f41a6","#91cc74","#fac858","#ee6666","#6c8ff6","#ea7ccc","#945cae","#fc8352","#3ba272","#73c0de"],
          data: seriesData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: true,
            color: 'white',
            fontWeight: 'bold',
            formatter: "{b}{d}%,{c}家",
          },
          labelLine: {
            lineStyle: {
              type:'dashed'
            }
          }
        }
      ]
    };
    this.options = options
  }
}
