import * as echarts from "echarts";

// 图表放大
function showFullScreen(option) {
  if (document.getElementById("full-chart")) {
    fullChart = null;
    // 由于修改了布局结构，所以只需要移除父节点就行了
    document.body.removeChild(document.getElementById("full-chart-bg"));
  } else {
    let dombg = document.createElement("div");
    dombg.id = "full-chart-bg";
    dombg.style.width = "100%";
    dombg.style.height = "100%";
    dombg.style.position = "absolute";
    dombg.style.top = "0";
    dombg.style.left = "0";
    dombg.style.margin = "0";
    dombg.style.zIndex = 9990;
    dombg.style.backgroundColor = "#000000b3";
    document.body.appendChild(dombg);

    let dom = document.createElement("div");
    dom.id = "full-chart";
    const width = window.innerWidth * 0.7;
    const height = window.innerHeight * 0.7;
    dom.style.width = width + "px";
    dom.style.height = height + "px";
    dom.style.margin = "10% auto" // 该盒子始终距离上方10%位置并左右居中
    dom.style.backgroundColor = "#0034488c";
    document.getElementById("full-chart-bg").appendChild(dom);
    let fullChart = echarts.init(dom, null, {
      width: width,
      height: height - 20,
    });
    option.toolbox[0].feature.myFullBack.show = true
    option.toolbox[0].feature.myFull.show = false
    option.title[0].show = true
    if (option.grid) { // 放大时，图表位置下来一些，不然会和标题重叠
      option.grid[0].top = 80
    }
    fullChart.setOption(option);
    window.addEventListener("resize", () => {
      fullChart.resize();
    });
  }
}

export {
  showFullScreen
}
