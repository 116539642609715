// 这是数说事件-上市央国企面板

import DataEventsBoard2SoprMaProjectsListingsCompaniesSoesController from "./sopr_ma_projects_listings_companies_soes_controller"; // board2 - 上市央国企（含直接参控股）国有产权并购项目
import DataEventsBoard2SoprMaProjectsEachRegionsListedCompaniesSoesController from "./sopr_ma_projects_each_regions_listed_companies_soes_controller"; // board2 - 各地区上市国有企业并购项目统计
import DataEventsBoard2SoprMaProjectsStateOwnedController from "./sopr_ma_projects_state_owned_controller"; // board2 - 国资在外地的并购项目
import DataEventsBoard2SoprMaProjectsStateOwnedOtherPlacesController from "./sopr_ma_projects_state_owned_other_places_controller"; // board2 - 外地国资在xxx的并购项目

function setup(application){
  application.register("data-events--board2--sopr-ma-projects-listings-companies-soes", DataEventsBoard2SoprMaProjectsListingsCompaniesSoesController)
  application.register("data-events--board2--sopr-ma-projects-each-regions-listed-companies-soes", DataEventsBoard2SoprMaProjectsEachRegionsListedCompaniesSoesController)
  application.register("data-events--board2--sopr-ma-projects-state-owned", DataEventsBoard2SoprMaProjectsStateOwnedController)
  application.register("data-events--board2--sopr-ma-projects-state-owned-other-places", DataEventsBoard2SoprMaProjectsStateOwnedOtherPlacesController)
}

export {
  setup
}
