// 这是数说企业-产业分布面板

import DataStoriesBoard3IndustrialDistributionSoesController from "./industrial_distribution_soes_controller" // board3 - 本地国有企业行业分布
import DataStoriesBoard3IndustrialDistributionCentralSoesController from "./industrial_distribution_central_soes_controller" // board3 - 本地央企行业分布
import DataStoriesBoard3IndustrialDistributionSoeHoldingController from "./industrial_distribution_soe_holding_controller" // board3 - 国有控股上市公司行业分布
import DataStoriesBoard3IndustrialDistributionOtherPlaceSoesController from "./industrial_distribution_other_place_soes_controller" // board3 - 归属外地的国有企业行业分布

import FullDataController from "./full_data_bar_chart_controller"; // board3 - 完整数据统计

function setup(application){
  application.register("data-stories--board3--full-data", FullDataController)

  application.register("data-stories--board3--industrial-distribution-soes", DataStoriesBoard3IndustrialDistributionSoesController)
  application.register("data-stories--board3--industrial-distribution-central-soes", DataStoriesBoard3IndustrialDistributionCentralSoesController)
  application.register("data-stories--board3--industrial-distribution-soe-holding", DataStoriesBoard3IndustrialDistributionSoeHoldingController)
  application.register("data-stories--board3--industrial-distribution-other-place-soes", DataStoriesBoard3IndustrialDistributionOtherPlaceSoesController)
}

export {
  setup
}
