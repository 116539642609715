// 这是数说企业-区域分布面板

import DataStoriesBoard2RegionalDistributionSoesController from './regional_distribution_soes_controller' // board2 - 四川省国有企业区域分布
import DataStoriesBoard2RegionalDistributionListedCompaniesController from "./regional_distribution_listed_companies_controller" // board2 - 四川省上市公司区域分布

function setup(application){
  application.register("data-stories--board2--regional-distribution-soes", DataStoriesBoard2RegionalDistributionSoesController)
  application.register("data-stories--board2--regional-distribution-listed-companies", DataStoriesBoard2RegionalDistributionListedCompaniesController)

}

export {
  setup
}
