import { Controller } from "@hotwired/stimulus";
import * as XLSX from 'xlsx';
export default class extends Controller {
  static targets = ["table","show","hidden"];
  download(event) {
    // 下载表格前 移除头部第一行的选择框，让隐藏的头部第二行正常的地名和交易所名显示
    this.showTarget.classList.remove('hidden')
    this.hiddenTarget.classList.add('hidden')
    var xlsxParam = { raw: true };// 转换成excel时，使用原始的格式
    var wb = XLSX.utils.table_to_book(this.tableTarget,xlsxParam);
    XLSX.writeFile(wb,"数据对比.xlsx",{
        bookType: 'xlsx'
    })
    // 恢复正常表格
    this.showTarget.classList.add('hidden')
    this.hiddenTarget.classList.remove('hidden')
  }

}
