import moment from "moment"

function TimeShortcutPlugin(pluginConfig) {
  const config = pluginConfig
  let confirmContainer

  return (fp) => {
    if (fp.config.noCalendar || fp.isMobile) return {}
    return {
      onReady() {
        confirmContainer = fp._createElement(
          "div",
          "p-2 z-99 bg-white border border-t-0 space-x-1 flex justify-between rounded-b"
        )

        confirmContainer.tabIndex = -1
        config.timeOptions.forEach( (item) => {
          confirmContainer.innerHTML += `<button class="time-picker__time-shortcut-button time-shortcut" data-type="${item.type}" data-value="${item.value}">${item.label}</button>`
        })
        fp.calendarContainer.appendChild(confirmContainer)
        TimeShortcutClickMount(fp)
      }
    }
  }
}

function TimeShortcutClickMount(fp) {
  fp.calendarContainer.querySelectorAll('.time-shortcut').forEach( (item) => {
    item.addEventListener('click', (e) => {
      let date = e.currentTarget.dataset.value
      let type = e.currentTarget.dataset.type
      fp.setDate([moment().subtract(date, type).format(), new Date()])
      fp.close()
    })
  })
}

export default TimeShortcutPlugin
