// 这是数说达人详情的图表js

import CentralEnterprisesProjectController from "./central_enterprises_project_controller";
import SoeProjectController from "./soe_project_controller";
import CentralEnterprisesProjectYearController from "./central_enterprises_project_year_controller";

function setup(application){
  application.register("data-contacts--central-enterprises-project", CentralEnterprisesProjectController)
  application.register("data-contacts--soe-project", SoeProjectController)
  application.register("data-contacts--central-enterprises-project-year", CentralEnterprisesProjectYearController)

}

export {
  setup
}
