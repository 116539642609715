import { Controller } from "@hotwired/stimulus";
import { get as getRequest } from '@rails/request.js'

export default class extends Controller {
  static values = { formName:String, url:String }
  static targets = ["template","searchBtn", "location", "input"]


  search(e) {
    e.preventDefault()
    this.resetLocation()
    this.disabledSearchBtn('<i class="fa fa-spinner"></i>')
    this.request(this.urlValue, { keyword: this.inputTarget.value }).then(response => {
      const data = response || []
      this.effectSearchBtn('<i class="fa fa-search"></i>')
      this.showLocation()

      const tips = document.createElement('div')
      tips.className = 'text-gray-300 text-xs px-2'
      if (data.length > 0) {
        tips.textContent = '已为您查询到以下结果'
        this.locationTarget.appendChild(tips)
        console.log(this.locationTarget);
        data.forEach(name => {
          const fragmentTempDoc = new DOMParser().parseFromString(this.templateTarget.innerHTML, 'text/html')
          const tempNode = fragmentTempDoc.body.firstChild
          tempNode.textContent = name
          this.locationTarget.appendChild(tempNode)
        })
      } else {
        tips.textContent = '暂无查询结果'
        this.locationTarget.appendChild(tips)
      }
    })
  }

  select(e) {
    e.preventDefault()
    const name = e.target.textContent
    this.disabledSearchBtn('<i class="fa fa-spinner"></i>')
    this.effectSearchBtn('<i class="fa fa-search"></i>')
    this.resetLocation()
    this.inputTarget.value = name
    document.getElementById(this.formNameValue).submit()
  }

  resetLocation() {
    this.hideLocation()
    this.locationTarget.innerHTML = ''
  }

  showLocation() {
    this.locationTarget.classList.remove('hidden')
  }

  hideLocation() {
    this.locationTarget.classList.add('hidden')
  }
  resetLocation() {
    this.hideLocation()
    this.locationTarget.innerHTML = ''
  }


  disabledSearchBtn(text='') {
    this.searchBtnTarget.classList.add('pointer-events-none', 'text-gray-300')
    if (!!text) { this.searchBtnTarget.innerHTML = text }
  }

  effectSearchBtn(text='') {
    this.searchBtnTarget.classList.remove('pointer-events-none', 'text-gray-300')
    if (!!text) {
      this.searchBtnTarget.innerHTML = text
    }
  }

  async request(url, params={}) {
    const response = await getRequest(url, { query: params, contentType: "application/json"})
    if (response.ok) {
      if (response.contentType == 'application/json') {
        return await response.json
      } else {
        console.error("response contentType isn't application/json")
        return []
      }
    }
  }


}
