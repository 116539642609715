import ChartController from "../../chart_controller";
import { showFullScreen } from "../../../utils";

export default class extends ChartController {
  setOptions() {
    let legends = Object.keys(Object.entries(this.dataValue)[0][1]["总记"]);
    let xAxis = [];
    for (const key in this.dataValue) {
      xAxis.push(key);
    }
    let seriesData = [];
    for (const legend of legends) {
      let outcome = [];
      for (const key in xAxis) {
        outcome[key] = Object.entries(this.dataValue)[key][1]["总记"][legend];
      }

      seriesData.push(outcome);
    }
    let yAxis1 = seriesData[0].concat(seriesData[2]);
    let yAxis2 = seriesData[1].concat(seriesData[3]);
    const data = this.dataValue;
    const colors = ["#178280", "#40EBEE", "#F0982F", "#FC5621"];
    const findItemDate = (key) => {
      return this.dataValue[key] || {}
    }
    const options = {
      title: {
        show: false,
        text: this.titleValue,
        top: "2%",
        left: "2%",
        textStyle: {
          color: "#fff",
        },
      },
      toolbox: {
        show: true,
        itemSize: 20,
        top: "2%",
        right: "2%",
        feature: {
          myFull: {
            show: true,
            title: "大图",
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAW9JREFUWEftlkFOwzAQRWd8B27QFgn2LV2khAMgVvQQcAjCihPAIdodFyDgRQn7INH2BtxhBhlqybUcRyR2qiK6yaKZ/Odv+88g7PiHO9aHvwVwKItLJjr2uUoC8nUyzvU7QR0YPC8yEOLGu61Et8vTcRYFQDlAgFc2AAIfAMCPMzEBbOHey+uRQJxtxMvvZ1cAtjgDfiJw2gmALU7MU0Rx3wmAS3w9OXnvy7en6ABV4upsRAfwiXcC0JfFIwKcA0Cp9lzZbt4KHVIoRPmRjOZRcmAgixkxZ7a4L5iCJmGTxvYPsF8OqKuGIO5Wk+FFk/121Ww5UHVVVKF1z+fLZDQNAbEFoPs5A+arZHimBepCpg1ILUBMcQXuBYgt7gVgpmtzmHDFaxvrnVFsngFjjHJmewjxSgdUQ9FjVKyVex3Y/FmqMcpeqQB+MLtZWyech/A3Y3VQgJ5cpIIg9bZPq58HBWj7sSb1+9WMmqywruYLOPX+Ibku0ksAAAAASUVORK5CYII=",
            onclick: (e) => {
              showFullScreen(e.getOption());
            },
          },
          myFullBack: {
            show: false,
            title: "缩小",
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAaZJREFUWEfFlbtKA0EUhv/ZFPoMVhZhES3scjWraQQ7G9NZKtgK6WMv2Ap2dpo+rUk2kqx2YoRsIrY+hMyRiRlZ170Edmd2HmC+b/5zzhyGjA/Tzc/3R5szq/QmuVoFBNxg7J4TNaSENgEJB7AFYCwltAiEwUUZlAtEwVMTMPvOIQy24u4U7rxNHQdPT2DgfICwDuKnrlW+ERcvA09PwHZIvpwYNYmjI7rd33BBI59KD5gegQVkvAxcSQKeV/6OWtRnpyqBOZMRu5xYhWZmAgJMhOupVTwLk0grgddFzYM5nF+4u+WWuibsDVswcicArfkhBNad1gp1pQkk2aiplECZgGk7jwAqgQDClWsVz5PAI/+BvD3cM2A8RI4Q0fbEKr0kkYgswUbvqcINEikEHg5en9XKXWUC4mJz8HwA4p0giBaBuYTtiMVy5JfQIhAGFzLKBbxwBjQItAqwW5mEUgE/fFIrtn/KMTqWEtz4ys+q1ffUmzAMLkH53mg/x/CZdAQD/4E4eJLXxi4j3fA/CWQB/1cCIcGAtmy4tOOOLYEOoJ+R+Tr+Bkcm4yHTPcxWAAAAAElFTkSuQmCC",
            onclick: (e) => {
              showFullScreen(e.getOption());
            },
          },
        },
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: "#18818b91",
        borderColor: "#18818B",
        textStyle: {
          color: "#fff", //设置文字颜色
        },
        // 解决外层盒子加了overfolaow：hidden属性后，tooltip有时候显示不完整的问题
        position: function (point, params, dom, rect, size) {
          var x = 0; // x坐标位置
          var y = 0; // y坐标位置

          // 当前鼠标位置
          var pointX = point[0];
          var pointY = point[1];

          // 提示框大小
          var boxWidth = size.contentSize[0];
          var boxHeight = size.contentSize[1];

          // boxWidth > pointX 说明鼠标左边放不下提示框
          if (boxWidth > pointX) {
            x = 5;
          } else {
            // 左边放的下
            x = pointX - boxWidth;
          }

          // boxHeight > pointY 说明鼠标上边放不下提示框
          if (boxHeight > pointY) {
            y = 5;
          } else {
            // 上边放得下
            y = pointY - boxHeight;
          }

          return [x, y];
        },
        formatter: function (params) {
          const cityName = params[0]['axisValue']
          const baseData = findItemDate(cityName)
          // console.log(params);
          return (
            "<div>" +
            cityName +
            "<br>" +
            "非上市国有产权并购项目挂牌" +
            baseData['总记']['挂牌数量'] +
            "宗," +
            "挂牌金额" +
            baseData['总记']['挂牌金额'] +
            "亿元;其中：" +
            "<br>" +
            "股权转让挂牌" +
            data[cityName]['股权转让']['挂牌数量'] +
            "宗," +
            "挂牌金额" +
            data[cityName]['股权转让']['挂牌金额'] +
            "亿元;" +
            "<br>" +
            "企业增资挂牌" +
            data[cityName]["增资扩股"]["挂牌数量"] +
            "宗," +
            "挂牌金额" +
            data[cityName]["增资扩股"]["挂牌金额"] +
            "亿元;" +
            "<br>" +
            "非上市国有产权并购成交数量" +
            baseData['总记']['成交数量'] +
            "宗," +
            "成交金额" +
            baseData['总记']['成交金额'] +
            "亿元;其中：" +
            "<br>" +
            "股权转让成交" +
            data[cityName]["股权转让"]["成交数量"] +
            "宗," +
            "成交金额" +
            data[cityName]["股权转让"]["成交金额"] +
            "亿元;" +
            "<br>" +
            "企业增资成交" +
            data[cityName]["增资扩股"]["成交数量"] +
            "宗," +
            "成交金额" +
            data[cityName]["增资扩股"]["成交金额"] +
            "亿元;" +
            "</div>"
          );
        },
      },
      legend: {
        data: legends,
        itemHeight: 12,
        itemWidth: 12,
        icon: "rect",
        bottom: "left",
        left: "7%",
        textStyle: {
          color: "#8DA7B3",
        },
      },
      dataZoom: [
        {
          show: xAxis.length > 8 ? true : false,
          id: "dataZoomX",
          type: "slider",
          xAxisIndex: [0],
          filterMode: "empty",
          startValue: 0,
          endValue: 7,
          height:8,
          brushSelect: false,
          fillerColor: '#168280',
          borderColor: "#178280",
          handleSize: 0,
          showDataShadow: false, // 是否显示数据阴影 默认auto
          showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
        },
      ],
      xAxis: {
        type: "category",
        data: xAxis,
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: "#18818B",
            width: 2,
          },
        },
        axisLabel: {
          color: "#8DA7B3",
        },
      },
      yAxis: [
        {
          type: "log",
          name: "项目数(宗)",
          min: 1,
          logBase: Math.max(Math.ceil(Math.pow(Math.max(...yAxis1),0.25)), 2),

          nameTextStyle: {
            color: "#8DA7B3",
          },
          axisLine: {
            show: true,
            onZero: true,
            lineStyle: {
              color: "#18818B",
              width: 2,
            },
          },
          axisLabel: {
            color: "#8DA7B3",
            formatter: (params) => {
              return params === 1 ? 0 : params;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#18818B",
              type: "dashed",
            },
          },
        },
        {
          type: "log",
          name: "金额(亿元)",
          min: 1,
          logBase: Math.max(Math.ceil(Math.pow(Math.max(...yAxis2),0.25)), 2),
          nameTextStyle: {
            color: "#8DA7B3",
          },
          axisLine: {
            show: false,
            onZero: true,
            lineStyle: {
              color: "#18818B",
              width: 2,
            },
          },
          axisLabel: {
            color: "#8DA7B3",
            formatter: (params) => {
              return params === 1 ? 0 : params;
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#18818B",
              type: "dashed",
            },
          },
        },
      ],
      series: seriesData.map((item, index) => {
        return {
          name: legends[index],
          type: "bar",
          color: colors[index],
          yAxisIndex: index % 2 == 0 ? 0 : 1,
          barMaxWidth: "25",
          label: {
            show: false,
            position: "top",
            color: "white",
            fontWeight: "bold",
            formatter: function (param) {
              return Math.round(parseFloat(param.data - 1) * 10000) / 10000;
            },
          },
          data: item.map((i) => {
            return i + 1;
          }),
        };
      }),
    };
    this.options = options;
  }
}
