import { FetchRequest } from "@rails/request.js";

function countDown() {
  if(this.counter > 1){
    this.element.textContent = this.format(--this.counter)
    setTimeout(countDown.bind(this), 1000)
  }else{
    this.element.textContent = this.originText
  }
}

function standardizePhoneNumber(str){
  return str.replace(/\D/g, "")
}

export default function (Alpine) {
  Alpine.data("SendMobilePhoneCode", () => ({
    url: "",
    message: "",
    phoneNumber: "",
    sent: false,
    code: "",
    get phoneNumberNotRequired(){
      return this.$refs.input.dataset.notRequired == "true"
    },
    get validPhoneNumber() {
      return standardizePhoneNumber(this.phoneNumber).match(/^\d{11}$/);
    },
    init() {
      this.$watch('phoneNumber', (v) => this.reset())
    },
    inputBind: () => ({
      "data-not-required": "false",
      "x-ref": "input",
      "x-mask": "999-9999-9999",
      "x-model": "phoneNumber",
      "@keydown.enter.prevent": "sendCode",
      ":readonly": "sent"
    }),
    buttonBind: () => ({
      "x-ref": "button",
      "@click": "sendCode",
      ":disabled": "sent || (!phoneNumberNotRequired && !validPhoneNumber)",
    }),
    codeBind: () =>({
      'x-ref': 'code',
      'x-mask': '9999',
      'x-model': 'code'
    }),
    reset() {
      this.message = '';
      this.sent = false;
    },
    sendCode() {
      // console.log('this.phoneNumberNotRequired', this.phoneNumberNotRequired)
      // console.log('this.validPhoneNumber', this.validPhoneNumber)
      // console.log('this.refs.code', this.$refs)
      if (this.sent || (!this.phoneNumberNotRequired && !this.validPhoneNumber)) return;
      // console.log("send code", this.phoneNumber);

      const req = new FetchRequest("post", this.url, {
        body: JSON.stringify({ mobile_phone: standardizePhoneNumber(this.phoneNumber) }),
      });
      this.sent = true;
      req
        .perform()
        .then((resp) => {
          if (resp.ok) {
            resp.json
              .then((data) => {
                const { error_code, message } = data;
                if (error_code == 0) {
                  const context = {
                    element: this.$refs['button'],
                    counter: 120,
                    originText: this.$refs['button'].textContent,
                    format: (counter) => `${counter}秒后可重发`,
                    complete: () => this.sent = false
                  }
                  countDown.bind(context)()
                  this.$refs['code'] && this.$refs['code'].focus();
                }else{
                  this.sent = false;
                }
                this.message = message;
              })
              .catch((ex) => {
                this.message = "发送失败!!!";
                this.sent = false
                console.error(ex)
              });
          } else {
            this.message = "发送失败，请稍后再试。";
            this.sent = false
          }
        })
        .catch((_resp) => {
          this.message = "发送失败，可能网络出现了问题，请稍后再试。";
          this.sent = false
        });
    },
  }));
}
