// 这是数说事件-非上市国企面板

import DataEventsBoard1SoprMaProjectsListingsController from "./sopr_ma_projects_listings_controller"; // board1 - 非上市国企产权并购项目统计
import DataEventsBoard1SoprMaProjectsTransactionsController from "./sopr_ma_projects_transactions_controller"; // board1 - 各地区非上市国有产权并购项目统计
import DataEventsBoard1SoprMaProjectsController from "./sopr_ma_projects_controller"; // board1 - 上市央企国有产权并购项目
import DataEventsBoard1SoprMaProjectsEachRegionsController from "./sopr_ma_projects_each_regions_controller"; // board1 - 各地区非上市央企国有产权并购项目统计
function setup(application){
  application.register("data-events--board1--sopr-ma-projects-listings", DataEventsBoard1SoprMaProjectsListingsController)
  application.register("data-events--board1--sopr-ma-projects-transactions", DataEventsBoard1SoprMaProjectsTransactionsController)
  application.register("data-events--board1--sopr-ma-projects", DataEventsBoard1SoprMaProjectsController)
  application.register("data-events--board1--sopr-ma-projects-each-regions", DataEventsBoard1SoprMaProjectsEachRegionsController)
}

export {
  setup
}
