import ChartController from "../../chart_controller";
export default class extends ChartController {
  setOptions() {
    let seriesData1 = []
    let seriesData2 = []
    for (const key in this.dataValue) {
      if (Object.hasOwnProperty.call(this.dataValue, key)) {
        const group = this.dataValue[key]
        seriesData1.push({name: key + '挂牌数量', value: group['挂牌数量'] })
        seriesData2.push({name: key + '成交金额', value: group['成交金额'] })
      }
    }
    const options = {
      tooltip: {
        trigger: "item",
        show:false,
        backgroundColor: "#18818B",
        formatter: '{b} : <br/> {d}%',
        textStyle: {
          color: "#fff", //设置文字颜色
        },
      },
      grid: {
        bottom: "35%",
      },
      legend: {
        bottom: "left",
        left: "7%",
        textStyle: {
          color: "#8DA7B3",
        },
        show: false,
      },
      series: [
        {
          type: "pie",
          radius: ["20%", "40%"],
          // clockWise: false,
          color: ["#168280a1", "#40ebee8f"],
          data: seriesData1,
          avoidLabelOverlap: false,
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              height: 35,
              position: "outside",
              formatter: "{ng|{b}} \n {num|{c}宗} {per|{d}%}",
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  fontSize: 12,
                  padding: [5, 10, 0, 10],
                  align: "center",
                  color: "#fff",
                },
                num: {
                  fontSize: 14,
                  color: "#fff"
                },
                per: {
                  fontSize: 14,
                  align: "center",
                  padding: [0, 10, 0, 10],
                  color: "#fff",
                },
              },
            },
          },
          labelLine: {
            normal: {
              length: 40,
              length2: 20,
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          type: "pie",
          radius: ["50%", "70%"],
          color: ["#fc5621ad", "#f0982fbf"],
          data: seriesData2,
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              height: 35,
              position: "outside",
              formatter: "{ng|{b}} \n {num|{c}亿元} {per|{d}%}",
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  fontSize: 12,
                  padding: [5, 10, 0, 10],
                  align: "center",
                  color: "#fff",
                },
                num: {
                  fontSize: 14,
                  color: "#fff"
                },
                per: {
                  fontSize: 14,
                  align: "center",
                  padding: [0, 10, 0, 10],
                  color: "#fff",
                },
              },
            },
          },
          labelLine: {
            normal: {
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    this.options = options
  }

}
