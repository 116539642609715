// 这是数说机构-六大交易所央企项目市场份额统计

import PieCentralZengziProjectByMpsController from "./pie_central_zengzi_project_by_mps_controller"; // board2 - 六大交易所央企增资项目市场份额统计饼图
import CentralZengziProjectMarketController from "./central_zengzi_project_market_controller"; // board2 - 央企增资转让项目市场份额统计
function setup(application){
  application.register("data-mps--board2--pie-central-zengzi-project-by-mps", PieCentralZengziProjectByMpsController)
  application.register("data-mps--board2--central-zengzi-project-market", CentralZengziProjectMarketController)
}

export {
  setup
}
