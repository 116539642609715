import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";
export default class extends Controller {
  static targets = ["chart"];
  static values = {
    data: Array
  }
  connect() {
    let legends = Object.keys(this.dataValue[0])
    legends.splice(legends.indexOf("年份"), 1)
    let seriesData = []
    let years = []
    for (const legend of legends) {
      let outcome = [];
      for (const key in this.dataValue) {
        outcome[key] = this.dataValue[key][legend];
        years[key] = this.dataValue[key]['年份'];
      }
      seriesData.push(outcome);
    }
    // 折线柱状图1
    var chart = echarts.init(this.chartTarget, null, {
      width: this.chartTarget.clientWidth,
      height: this.chartTarget.clientHeight,
    });

    var option = {
      grid: {
        left: "5%",
        top: "15%",
        bottom: "20%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#e3f6ff",
          },
        },
      },
      toolbox: {},
      legend: {
        y: "bottom",
        left: "5%",
        itemHeight: 12,
        itemWidth: 12,
        data: [
          {
            name: legends[0],
            icon:"rect"
          },
          {
            name: legends[1],
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAGdJREFUWEdjZBhgwDjA9jOMOmA0BEZDYDQERkNgNAQYu1+UcDNy/tf6/4+Rm64V03+Gd8yCf28z9rwrjv7PyLCErpZDLWNkYsgeeAcMeBQMRNAj2zmaDUdDYDQERkNgNARGQ2DAQwAAEXoa44pp8ecAAAAASUVORK5CYII="
          },
          {
            name: legends[2],
            icon:"rect"
          },
          {
            name: legends[3],
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAIxJREFUWEdjZBhgwDjA9jOMOmA0BEZDYDQERkNgNARQQuDnzQSNv/+ZdWlZQTGx/b/EoTTvJswOuAM+X03VZmX9d4WWlsPM/s34T5tXdf41EH9AHMDI+E+bHd0BINf8vJmm8ff/Hz1ahgIzI8sldvVZNzCigJaW4jN7NBuOhsBoCIyGwGgIjIbAgIcAAEY4HyE/vuRTAAAAAElFTkSuQmCC"
          }
        ],
        textStyle: {

          color: "#e3f6ff",
        }
      },
      xAxis: [
        {
          type: "category",
          data: years,
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#e3f6ff",
              // fontSize: fontSize(0.12)
            },
          },
          axisLine: {
            lineStyle: {
              color: "#18818B",
              width: 1, //这里是为了突出显示加上的
            },
          }
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "项目数(宗)",
          min: 0,
          max: Math.ceil(Math.max(...seriesData[0])),
          nameTextStyle:{

            color: "#e3f6ff",
          },
          axisLabel: {
            formatter: "{value}",
            show: true,
            textStyle: {
              color: "#e3f6ff",

            },
          },
          axisLine: {
            show: true,
            onZero: true,
            lineStyle: {
              color: '#18818B',
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#18818B',
              type: 'dotted'
            }
          }
        },
        {
          type: "value",
          name: "金额(亿元)",
          min: 0,
          max: Math.ceil(Math.max(...seriesData[1])),
          nameTextStyle:{

            color: "#e3f6ff",
          },
          axisLabel: {
            formatter: "{value}",
            show: true,
            textStyle: {
              color: "#e3f6ff",

            },
          },
          axisLine: {
            lineStyle: {
              color: "#18818B",
              width: 1, //这里是为了突出显示加上的
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: legends[0],
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return value + "宗";
            }
          },
          data: seriesData[0],
          barWidth: 12,
          itemStyle: {
            normal: {
              color: "#178280",
            },
          },
        },
        {
          name: legends[2],
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return value + "宗";
            },
          },
          data: seriesData[2],
          barWidth: 12,
          itemStyle: {
            normal: {
              color: "#C1E5F5",
            },
          },
        },
        {
          name: legends[1],
          type: "line",
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return value + " 亿";
            },
          },
          data: seriesData[1],
          smooth: false,
          itemStyle: {
            normal: {
              color: "#7FDA69",
              lineStyle: {
                color: "#7FDA69",
                width: 2
              }
            },
          },
        },
        {
          name: legends[3],
          type: "line",
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return value + " 亿";
            },
          },
          data: seriesData[3],
          itemStyle: {
            normal: {
              color: "#F8DA63",
              lineStyle: {
                color: "#F8DA63",
                width: 2,
                type:'dashed'
              }
            },
          },
        },
      ],
    };
    chart.setOption(option);
    chart.resize({
      width: this.chartTarget.clientWidth,
      height: this.chartTarget.clientHeight,
    });
    window.addEventListener("resize", () => {
      chart.resize({
        width: this.chartTarget.clientWidth,
        height: this.chartTarget.clientHeight,
      });
    });
  }
}
