import ChartController from "../../chart_controller";
export default class extends ChartController {

  setOptions() {
    let seriesData1 = []
    let seriesData2 = []
    let seriesData3 = []
    let seriesData4 = []
    for (const key in this.dataValue) {
      if (Object.hasOwnProperty.call(this.dataValue, key)) {
        const group = this.dataValue[key]
        seriesData1.push({name: key, value: group['挂牌数'] })
        seriesData2.push({name: key, value: group['项目成交数'] })
        seriesData3.push({name: key, value: group['挂牌金额'] })
        seriesData4.push({name: key, value: group['项目成交金额'] })
      }
    }
    const options = {
      title: [
        {
            text: '挂牌',
            left:'14%',
            top:'48%',
            textStyle: {
                color: '#fff',
                fontSize: 12
            }
        },
        {
            text: '成交',
            left:'34%',
            top:'48%',
            textStyle: {
                color: '#fff',
                fontSize: 12
            }
        },
        {
          text: '挂牌',
          left:'64%',
          top:'48%',
          textStyle: {
              color: '#fff',
              fontSize: 12
          }
      },
      {
          text: '成交',
          left:'84%',
          top:'48%',
          textStyle: {
              color: '#fff',
              fontSize: 12
          }
      }
      ],
      tooltip: {
        trigger: "item",
        show:true,
        extraCssText: 'background-color:rgba(0,0,0,0.3);color:#fff;padding:0;',
        formatter: function (params) {
          let nameArr = ['挂牌数','成交数','挂牌金额','成交金额']
          let unitArr = ['宗','宗','亿元','亿元']
          let htmlStr = `<div style='background: ${params.color};padding:5px; text-align: center; opacity: 0.8;'> ${params.name}<br>${nameArr[params.seriesIndex]}${params.value}${unitArr[params.seriesIndex]}<br>占比${params.percent}%</div>`
          return htmlStr;
        }
      },
      grid: {
        bottom: "35%",
      },
      legend: {
        bottom: "left",
        left: "7%",
        itemHeight: 14,
        itemWidth: 14,
        icon:'rect',
        textStyle: {
          color: "#8DA7B3",
        },
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "50%"],
          center: ['15%', '50%'],
          minAngle: 5,
          avoidLabelOverlap: false,
          color: ["#5470C6", "#FAC858", "#73C1DE","#91CB74","#ED6665","#3BA272"],
          data: seriesData1,
          itemStyle: {
            borderRadius: 2,
            borderColor: '#012035',
            borderWidth: 1
          },
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              opacity:0.7,
              position: "outside",
              // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: function (params) { // 默认显示第一个数据
                if (params.dataIndex === 0) {
                  return '{ng|' + params.name + '}' + '\n{num|挂牌数' + params.value + '宗}' + '\n{ng|占比' + params.percent + '%}'
                } else {
                  return ''
                }
              },
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  fontSize: 12,
                  padding: [5, 10, 5, 10],
                  align: "center",
                  color: "#fff",
                },
                num: {
                  fontSize: 12,
                  align: "center",
                  color: "#fff",
                }
              },
            },
          },
          labelLine: {
            normal: {
              length: 0,
              length2: 0,
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          type: "pie",
          radius: ["40%", "50%"],
          center: ['35%', '50%'],
          minAngle: 5,
          avoidLabelOverlap: false,
          color: ["#5470C6", "#FAC858", "#73C1DE","#91CB74","#ED6665","#3BA272"],
          data: seriesData2,
          itemStyle: {
            borderRadius: 2,
            borderColor: '#012035',
            borderWidth: 1
          },
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              opacity:0.7,
              position: "outside",
              // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: function (params) { // 默认显示第一个数据
                if (params.dataIndex === 0) {
                  return '{ng|' + params.name + '}' + '\n{num|成交数' + params.value + '宗}' + '\n{ng|占比' + params.percent + '%}'
                } else {
                  return ''
                }
              },
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  fontSize: 12,
                  padding: [5, 10, 5, 10],
                  align: "left",
                  color: "#fff",
                },
                num: {
                  fontSize: 12,
                  align: "center",
                  color: "#fff",
                }
              },
            },
          },
          labelLine: {
            normal: {
              length: 0,
              length2: 0,
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          type: "pie",
          radius: ["40%", "50%"],
          center: ['65%', '50%'],
          minAngle: 5,
          avoidLabelOverlap: false,
          color: ["#5470C6", "#FAC858", "#73C1DE","#91CB74","#ED6665","#3BA272"],
          data: seriesData3,
          itemStyle: {
            borderRadius: 2,
            borderColor: '#012035',
            borderWidth: 1
          },
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              opacity:0.7,
              position: "outside",
              // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: function (params) { // 默认显示第一个数据
                if (params.dataIndex === 0) {
                  return '{ng|' + params.name + '}' + '\n{num|挂牌金额' + params.value + '亿元}' + '\n{ng|占比' + params.percent + '%}'
                } else {
                  return ''
                }
              },
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  fontSize: 12,
                  padding: [5, 10, 5, 10],
                  align: "left",
                  color: "#fff",
                },
                num: {
                  fontSize: 12,
                  align: "left",
                  color: "#fff",
                }
              },
            },
          },
          labelLine: {
            normal: {
              length: 0,
              length2: 0,
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          type: "pie",
          radius: ["40%", "50%"],
          center: ['85%', '50%'],
          minAngle: 5,
          avoidLabelOverlap: false,
          color: ["#5470C6", "#FAC858", "#73C1DE","#91CB74","#ED6665","#3BA272"],
          data: seriesData4,
          itemStyle: {
            borderRadius: 2,
            borderColor: '#012035',
            borderWidth: 1
          },
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              opacity:0.7,
              position: "outside",
              // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: function (params) { // 默认显示第一个数据
                if (params.dataIndex === 0) {
                  return '{ng|' + params.name + '}' + '\n{num|成交金额' + params.value + '亿元}' + '\n{ng|占比' + params.percent + '%}'
                } else {
                  return ''
                }
              },
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  fontSize: 12,
                  padding: [5, 10, 5, 10],
                  align: "left",
                  color: "#fff",
                },
                num: {
                  fontSize: 12,
                  align: "left",
                  color: "#fff",
                }
              },
            },
          },
          labelLine: {
            normal: {
              length: 0,
              length2: 0,
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        }
      ],
    };
    this.options = options
  }
}
