import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scrollBox","list1","list2"];
  connect() {
    this.list2Target.innerHTML=this.list1Target.innerHTML;
    this.scroll();
  }

  scrollEvent(){
    // 判断是否滚动到底,经过调试发现存在一点误差，因此判断误差3px以内就到底了
    if((this.scrollBoxTarget.scrollHeight - this.scrollBoxTarget.offsetHeight) - this.scrollBoxTarget.scrollTop < 3){
      this.scrollBoxTarget.scrollTop -= this.list1Target.offsetHeight;
    }else{
      this.scrollBoxTarget.scrollTop++
    }
  }

  scroll(){
    this.myScroll = setInterval(() => {
      this.scrollEvent();
    }, 60)
  }

  disconnect() {
    clearInterval(this.myScroll)
  }
}
