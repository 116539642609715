import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";

export default class extends Controller {
  connect() {
    const options = {
      legend: {
        data: ["股权转让挂牌数", "企业增资挂牌数", "股权转让挂牌金额"],
        bottom: "left",
        left: "7%",
        textStyle: {
          color: "#8DA7B3",
        },
      },
      xAxis: {
        type: "category",
        data: ["2018", "2019", "2020", "2021", "2022"],
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: "#18818B",
            width: 2,
          },
        },
        axisLabel: {
          color: "#8DA7B3",
        },
      },
      yAxis: [
        {
          type: "value",
          name: "项目数(家)",
          nameTextStyle: {
            color: "#8DA7B3",
          },
          axisLine: {
            show: true,
            onZero: true,
            lineStyle: {
              color: "#18818B",
              width: 2,
            },
          },
          axisLabel: {
            color: "#8DA7B3",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#18818B",
              type: "dashed",
            },
          },
        },
        {
          type: "value",
          name: "金额(亿元)",
          axisLabel: {
            formatter: "{value}",
            show: true,
            textStyle: {
              color: "#8DA7B3",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#18818B",
              width: 1, //这里是为了突出显示加上的
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: "股权转让挂牌数",
          type: "bar",
          color: "#178280",
          barWidth: "20%",
          label: {
            show: true,
            position: "top",
            color: "white",
            fontWeight: "bold",
          },
          data: [
            parseInt(Math.random(100) * 100 + 10),
            parseInt(Math.random(100) * 100 + 10),
            parseInt(Math.random(100) * 100 + 10),
            parseInt(Math.random(100) * 100 + 10),
            parseInt(Math.random(100) * 100 + 10),
          ],
        },
        {
          name: "企业增资挂牌数",
          type: "bar",
          color: "#40EBEE",
          barWidth: "20%",
          label: {
            show: true,
            position: "top",
            color: "white",
            fontWeight: "bold",
          },
          data: [
            parseInt(Math.random(100) * 10 + 10),
            parseInt(Math.random(100) * 10 + 10),
            parseInt(Math.random(100) * 10 + 10),
            parseInt(Math.random(100) * 10 + 10),
            parseInt(Math.random(100) * 10 + 10),
          ],
        },
        {
          name: "股权转让挂牌金额",
          type: "bar",
          color: "#F0982F",
          barWidth: "20%",
          label: {
            show: true,
            position: "top",
            color: "white",
            fontWeight: "bold",
          },
          data: [
            parseInt(Math.random(100) * 10 + 10),
            parseInt(Math.random(100) * 10 + 10),
            parseInt(Math.random(100) * 10 + 10),
            parseInt(Math.random(100) * 10 + 10),
            parseInt(Math.random(100) * 10 + 10),
          ],
        },
      ],
    };
    const w = this.element.clientWidth;
    const h = this.element.clientHeight;

    this.myChart = echarts.init(this.element, null, {
      width: w,
      height: h,
    });

    this.myChart.setOption(options);

    this.myChart.resize({
      width: this.element.clientWidth,
      height: this.element.clientHeight
    });

    window.addEventListener("resize", () => {
      this.myChart.resize({
        width: this.element.clientWidth,
        height: this.element.clientHeight
      });
    })
  }

  showFullScreen(option) {
    if (this.fullFlag) {
      this.fullFlag = false;
      this.fullChart = null;
      document.body.removeChild(document.getElementById("full-chart"));
    } else {
      this.fullFlag = true;
      let dom = document.createElement("div");
      dom.id = "full-chart";
      const width = window.innerWidth * 0.7;
      const height = window.innerHeight * 0.7;
      dom.style.width = width + "px";
      dom.style.height = height + "px";
      dom.style.position = "absolute";
      dom.style.top = "15%";
      dom.style.left = "15%";
      dom.style.zIndex = 9999;
      dom.style.backgroundColor = "#003448e6";
      document.body.appendChild(dom);
      this.fullChart = echarts.init(dom, null, {
        width: width,
        height: height - 20,
      });
      this.fullChart.setOption(option);
      window.addEventListener("resize", () => {
        this.fullChart.resize();
      });
    }
  }
  disconnect() {
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  }
}
