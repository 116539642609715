import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";
export default class extends Controller {
  static targets = ["chart"];
  static values = {
    data: Object
  }
  connect() {
    let seriesData = []
    for (const key in this.dataValue) {
      if (Object.hasOwnProperty.call(this.dataValue, key)) {
        const val = this.dataValue[key]
        seriesData.push({name: key, value: val})
      }
    }
    // 圆环图
    var chart = echarts.init(this.chartTarget, null, {
      width: this.chartTarget.clientWidth,
      height: this.chartTarget.clientHeight,
    });
    var option = {
      title: {
        show: false
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
        show: false,
      },
      series: [
        {
          type: "pie",
          radius: ["45%", "60%"],
          color: ["#F0982F", "#40EBEE", "#FC5621", "#178280", "#7FDA69","#ad78e8"],
          data: seriesData,
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              position: "outside",
              formatter: "{ng|{b}} \n {per|{d}%}",
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  fontSize: 12,
                  color: 'white',
                  align: "center",
                },
                per: {
                  fontSize: 20,
                  color: 'inherit',
                  align: "center",
                  // lineHeight: 20,
                },
              },
            },
          },
          labelLine: {
            lineStyle: {
              type:'dashed'
            }
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    chart.setOption(option);
    chart.resize({
      width: this.chartTarget.clientWidth,
      height: this.chartTarget.clientHeight,
    });
    window.addEventListener("resize", () => {
      chart.resize({
        width: this.chartTarget.clientWidth,
        height: this.chartTarget.clientHeight,
      });
    });
  }
}
