import { application } from "./controllers/application"

/********* dashboard **********/
// 并购事件热门行业
import HotIndustryInMaController from "../components/dashboard/hot_industry_in_ma/component_controller"
application.register("dashboard--hot-industry-in-ma", HotIndustryInMaController)

// 非上市公司统计
import UnLcgoEvent from "../components/board/un_lcgo_event/component_controller"
application.register("board--un-lcgo-event", UnLcgoEvent)
// 上市公司统计
import LcgoEvent from "../components/board/lcgo_event/component_controller"
application.register("board--lcgo-event", LcgoEvent)
// 直接参控股统计
import GoOwnershipMaProjectCount from "../components/board/go_ownership_ma_project_count/component_controller"
application.register("board--go-ownership-ma-project-count", GoOwnershipMaProjectCount)
// 实物资产统计
import PhysicalAssets from "../components/board/physical_assets/component_controller"
application.register("board--physical-assets", PhysicalAssets)
