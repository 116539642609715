import { Controller } from "@hotwired/stimulus";
import * as echarts from 'echarts';

export default class extends Controller {
  connect() {
    const options = {
      tooltip: {
        trigger: 'item',
        backgroundColor: "#18818B",
        textStyle: {
          color: "#fff" //设置文字颜色
        }
      },
      grid: {
        bottom: "35%"
      },
      legend: {
        bottom: 'left',
        left: '7%',
        textStyle: {
          color: '#8DA7B3'
        },
        show: false
      },
      series: [
        {
          name: '',
          type: 'pie',
          color: ['#F0982F','#40EBEE'],
          data: [
            { value: 735, name: '餐饮业' },
            { value: 580, name: '航天装备' },
            { value: 300, name: '通用机械' }
          ],
          label: {                        // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
              normal: {
                  show: true,             // 是否显示标签[ default: false ]
                  backgroundColor: "#18818B",
                  height: 35,
                  position: 'outside',
                     // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
                  // formatter: function (val) {
                  //     return val.name + "\n" + val.percent + "%";
                  //   },
                  // formatter: "{b}: \n {d}%",
                  formatter: '{ng|{b}} \n {per|{d}%}',
                  //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
                  rich:{
                      ng:{
                          fontSize:12,
                          padding: [5, 10, 0, 10],
                          align:'center',
                          color: "#fff",
                      },
                      per:{
                          fontSize:16,
                          align:'center',
                          padding: [0, 10, 0, 10],
                          color: "#fff",
                      },
                  },

              }
          },
          labelLine: {
            normal: {
                show: false
            }
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
  };
    const w = this.element.clientWidth
    const h = this.element.clientHeight
    this.myChart = echarts.init(this.element, null, {
      width: w,
      height: h,
    });
    this.myChart.setOption(options);

    this.myChart.resize({
      width: this.element.clientWidth,
      height: this.element.clientHeight
    });

    window.addEventListener("resize", () => {
      this.myChart.resize({
        width: this.element.clientWidth,
        height: this.element.clientHeight
      });
    })
  }

  disconnect() {
    window.addEventListener("resize", () => {
      this.myChart.resize();
    })
  }
}
