import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    width: Number,
    height: Number,
    name: String
  }

  connect() {
    this.setScale();
    window.onresize = this._debounce(this.setScale.bind(this), 1000)
  }

  setScale() {
    if (this.elX >= this.currentX) {
      this.reset()
      this.element.style.transform = `scale(${this.scaling})`
    } else {
      this.setElFlex();
    }
  }

  reset() {
    this.element.style.transformOrigin = 'left top';
    this.element.style.width = `${this.elX}px`;
    this.element.style.height = `${this.elY}px`;
  }

  setElFlex() {
    this.element.style.transform = null;
    this.element.style.height = "100%";
    this.element.style.width = "100%";
  }

  get elY() {
    return this.heightValue ? this.heightValue : 1440
  }

  get elX() {
    return this.widthValue ? this.widthValue : 2560
  }

  get currentX() {
    return document.documentElement.clientWidth || window.innerWidth
  }

  get currentY() {
    return document.documentElement.clientHeight || window.innerHeight
  }

  get scaling() {
    return [this.currentX / this.elX, this.currentY / this.elY]
  }

  _debounce = (fn, t) => {
    const delay = t || 500
    let timer
    return function() {
      const args = arguments
      if (timer) {
        clearTimeout(timer)
      }
      const context = this
      timer = setTimeout(() => {
        timer = null
        fn.apply(context, args)
      }, delay)
    }
  }
}
