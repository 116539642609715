import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import 'select2';
export default class extends Controller{
  static values = {
    multiple: Boolean
  }

  connect(){
    let multiple = this.multipleValue || false
    $('select.selectpicker').select2({
      allowClear: false,
      multiple: multiple,
      language: {
        noResults: function () {
          return '未找到结果';
        },
        searching: function () {
          return '搜索中…';
        },
      }
    })
  }
}
