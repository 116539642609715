import ChartController from "../chart_controller";
export default class extends ChartController {
  setOptions() {
    let seriesData1 = [{name: '成交数量', value: this.dataValue['成交']['数量'], percent: this.dataValue['成交']['数量占比']  }, {name: '总成交数量', value: Math.ceil(this.dataValue['成交']['数量'] / (this.dataValue['成交']['数量占比']/100) - this.dataValue['成交']['数量']), percent:  Math.round(((1 - (this.dataValue['成交']['数量占比']/100)) * 100) * 10000) / 10000  }]
    let seriesData2 = [{name: '挂牌数量', value: this.dataValue['挂牌']['数量'], percent: this.dataValue['挂牌']['数量占比']  }, {name: '总挂牌数量', value: Math.ceil(this.dataValue['挂牌']['数量'] / (this.dataValue['挂牌']['数量占比']/100) - this.dataValue['挂牌']['数量']), percent: Math.round(((1 - (this.dataValue['挂牌']['数量占比']/100)) * 100) * 10000) / 10000  }]
    let seriesData3 = [{name: '成交金额', value: this.dataValue['成交']['金额'], percent: this.dataValue['成交']['金额占比'] }, {name: '总成交金额', value: Math.round((this.dataValue['成交']['金额'] / (this.dataValue['成交']['金额占比']/100) - this.dataValue['成交']['金额'])*10000)/10000, percent: Math.round(((1 - (this.dataValue['成交']['金额占比']/100)) * 100) * 10000) / 10000   }]
    let seriesData4 = [{name: '挂牌金额', value: this.dataValue['挂牌']['金额'], percent: this.dataValue['挂牌']['金额占比'] }, {name: '总挂牌金额', value: Math.round((this.dataValue['挂牌']['金额'] / (this.dataValue['挂牌']['金额占比']/100) - this.dataValue['挂牌']['金额'])*10000)/10000, percent: Math.round(((1 - (this.dataValue['挂牌']['金额占比']/100)) * 100) * 10000) / 10000   }]
    const options = {
      tooltip: {
        trigger: "item",
        show:false,
        backgroundColor: "#18818B",
        formatter: '{b} : <br/> {d}%',
        textStyle: {
          color: "#fff", //设置文字颜色
        },
      },
      grid: {
        bottom: "35%",
      },
      legend: {
        bottom: "5%",
        left: "7%",
        textStyle: {
          color: "#8DA7B3",
        },
        show: true,
        itemHeight: 12,
        itemWidth: 12,
        data: [
          {
            name: "成交数量",
            icon:"rect"
          },
          {
            name: "挂牌数量",
            icon: "rect"
          },
          {
            name: "成交金额",
            icon:"rect"
          },
          {
            name: "挂牌金额",
            icon: "rect"
          }
        ]
      },
      series: [
        {
          type: "pie",
          radius: ["25%", "40%"],
          center: ["25%", "50%"],
          color: ["#B5D7E6", "#344c5c"],
          data: seriesData1,
          minAngle: 5,
          avoidLabelOverlap: false,
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              opacity:0.8,
              position: "outside",
              formatter: function (params) {
                if (params.dataIndex === 0) {
                  return '{ng|' + params.name + params.value + '宗}\n' + '{per|占比' + params.data.percent + '%}';
                } else {
                  return ''
                }
              },
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  color: "#fff",
                  padding: [5, 10, 0, 10],
                },
                per: {
                  color: "#fff",
                  padding: [5, 10, 5, 10],
                },
              },
            },
          },
          labelLine: {
            normal: {
              length: 0,
              length2: 0,
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          type: "pie",
          radius: ["50%", "65%"],
          center: ["25%", "50%"],
          color: ["#7FDA69", "#344c5c"],
          data: seriesData2,
          minAngle: 5,
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              opacity:0.8,
              position: "outside",
              formatter: function (params) {
                if (params.dataIndex === 0) {
                  return '{ng|' + params.name + params.value + '宗}\n' + '{per|占比' + params.data.percent + '%}';
                } else {
                  return ''
                }
              },
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  color: "#fff",
                  padding: [5, 10, 0, 10],
                },
                per: {
                  color: "#fff",
                  padding: [5, 10, 5, 10],
                },
              },
            },
          },
          labelLine: {
            normal: {
              length: 0,
              length2: 0,
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          type: "pie",
          radius: ["25%", "40%"],
          center: ["70%", "50%"],
          color: ["#18818B", "#344c5c"],
          data: seriesData3,
          minAngle: 5,
          avoidLabelOverlap: false,
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              opacity:0.8,
              position: "outside",
              // formatter: "{ng|{b}} \n {num|{c}亿元} {per|{d}%}",
              formatter: function (params) {
                if (params.dataIndex === 0) {
                  return '{ng|' + params.name + params.value + '亿元}\n' + '{per|占比' + params.data.percent + '%}';
                } else {
                  return ''
                }
              },
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  color: "#fff",
                  padding: [5, 10, 0, 10],
                },
                per: {
                  color: "#fff",
                  padding: [5, 10, 5, 10],
                },
              },
            },
          },
          labelLine: {
            normal: {
              length: 0,
              length2: 0,
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          type: "pie",
          radius: ["50%", "65%"],
          center: ["70%", "50%"],
          color: ["#F0982F", "#344c5c"],
          data: seriesData4,
          minAngle: 5,
          label: {
            // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
            normal: {
              show: true, // 是否显示标签[ default: false ]
              backgroundColor: "inherit",
              opacity:0.8,
              position: "outside",
              // formatter: "{ng|{b}} \n {num|{c}亿元} {per|{d}%}",
              formatter: function (params) {
                if (params.dataIndex === 0) {
                  return '{ng|' + params.name + params.value + '亿元}\n' + '{per|占比' + params.data.percent + '%}';
                } else {
                  return ''
                }
              },
              //在rich中给formatter添加个别字体颜色，此处的abc对应formatter中的abc
              rich: {
                ng: {
                  color: "#fff",
                  padding: [5, 10, 0, 10],
                },
                per: {
                  color: "#fff",
                  padding: [5, 10, 5, 10],
                },
              },
            },
          },
          labelLine: {
            normal: {
              length: 0,
              length2: 0,
              lineStyle: {
                type:'dashed'
              },
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    this.options = options
  }

}
