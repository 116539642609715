// 数说企业/区域分布/国有企业区域分布
import ChartController from "../../chart_controller";
import { showFullScreen } from '../../../utils'
export default class extends ChartController {
  setOptions() {
    let xAxis = []
    let seriesData = []
    for (const key in this.dataValue) {
      xAxis.push(key)
      const indexOfKey = xAxis.indexOf(key)
      seriesData[indexOfKey] = this.dataValue[key]
    }

    const options = {
      title: {
        show: false,
        text: this.titleValue,
        top: "2%",
        left: "2%",
        textStyle: {
          color: '#fff'
        }
      },
      toolbox:{
        show: true,
        itemSize: 20,
        top: "2%",
        right: "2%",
        feature: {
          myFull: {
            show: true,
            title: '大图',
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAW9JREFUWEftlkFOwzAQRWd8B27QFgn2LV2khAMgVvQQcAjCihPAIdodFyDgRQn7INH2BtxhBhlqybUcRyR2qiK6yaKZ/Odv+88g7PiHO9aHvwVwKItLJjr2uUoC8nUyzvU7QR0YPC8yEOLGu61Et8vTcRYFQDlAgFc2AAIfAMCPMzEBbOHey+uRQJxtxMvvZ1cAtjgDfiJw2gmALU7MU0Rx3wmAS3w9OXnvy7en6ABV4upsRAfwiXcC0JfFIwKcA0Cp9lzZbt4KHVIoRPmRjOZRcmAgixkxZ7a4L5iCJmGTxvYPsF8OqKuGIO5Wk+FFk/121Ww5UHVVVKF1z+fLZDQNAbEFoPs5A+arZHimBepCpg1ILUBMcQXuBYgt7gVgpmtzmHDFaxvrnVFsngFjjHJmewjxSgdUQ9FjVKyVex3Y/FmqMcpeqQB+MLtZWyech/A3Y3VQgJ5cpIIg9bZPq58HBWj7sSb1+9WMmqywruYLOPX+Ibku0ksAAAAASUVORK5CYII=",
            onclick: (e) => {
              showFullScreen(e.getOption())
            },
          },
          myFullBack: {
            show: false,
            title: '缩小',
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAaZJREFUWEfFlbtKA0EUhv/ZFPoMVhZhES3scjWraQQ7G9NZKtgK6WMv2Ap2dpo+rUk2kqx2YoRsIrY+hMyRiRlZ170Edmd2HmC+b/5zzhyGjA/Tzc/3R5szq/QmuVoFBNxg7J4TNaSENgEJB7AFYCwltAiEwUUZlAtEwVMTMPvOIQy24u4U7rxNHQdPT2DgfICwDuKnrlW+ERcvA09PwHZIvpwYNYmjI7rd33BBI59KD5gegQVkvAxcSQKeV/6OWtRnpyqBOZMRu5xYhWZmAgJMhOupVTwLk0grgddFzYM5nF+4u+WWuibsDVswcicArfkhBNad1gp1pQkk2aiplECZgGk7jwAqgQDClWsVz5PAI/+BvD3cM2A8RI4Q0fbEKr0kkYgswUbvqcINEikEHg5en9XKXWUC4mJz8HwA4p0giBaBuYTtiMVy5JfQIhAGFzLKBbxwBjQItAqwW5mEUgE/fFIrtn/KMTqWEtz4ys+q1ffUmzAMLkH53mg/x/CZdAQD/4E4eJLXxi4j3fA/CWQB/1cCIcGAtmy4tOOOLYEOoJ+R+Tr+Bkcm4yHTPcxWAAAAAElFTkSuQmCC",
            onclick: (e) => {
              showFullScreen(e.getOption())
            },
          }
        }
      },
      dataZoom: [
        {
          show: xAxis.length > 31 ? true : false,
          id: "dataZoomX",
          type: "slider",
          xAxisIndex: [0],
          filterMode: "empty",
          startValue: 0,
          endValue: 30,
          height:8,
          brushSelect: false,
          fillerColor: '#168280',
          borderColor: "#178280",
          handleSize: 0,
          showDataShadow: false, // 是否显示数据阴影 默认auto
          showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
        },
      ],
      xAxis: {
        type: 'category',
        data: xAxis,
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: '#18818B',
            width: 2
          }
        },
        axisLabel: {
          color: '#8DA7B3',
          interval:0,
          formatter: function (value, index) { // 文字太多了的时候，图表挤的密密麻麻，就显示前 3 个字就行了
            if(value.length>3){
              return value.substr(0,3)+'..'
            }else{
              return value
            }
          }
        }
      },
      yAxis: {
        type: 'log',
        name: '企业数(家)',
        min: 1,
        logBase: Math.max(Math.ceil(Math.pow(Math.max(...seriesData),0.25)), 2),
        nameTextStyle: {
          color: '#8DA7B3'
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: '#18818B',
            width: 2
          }
        },
        axisLabel: {
          color: '#8DA7B3',
          formatter: (params) => {
            return params === 1 ? 0 : params;
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#18818B',
            type: 'dashed'
          }
        }
      },
      series: [
        {
          data: seriesData.map((i) => {
            return i + 1
          }),
          type: 'bar',
          barMaxWidth: '25',
          color: '#F0982F',
          label: {
            show: true,
            position: 'top',
            color: 'white',
            fontWeight: 'bold',
            formatter: function (param) {
              return  Math.round(parseFloat(param.data - 1) * 10000) / 10000
            }
          }
        }
      ]
    };
    this.options = options
  }

}
