import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["list"];
  connect() {
    var ele = this.listTarget.children;
    var length = ele.length;
    var i = 1;
    setInterval(function () {
      $(ele[i]).find(".cicle").removeClass("bg-green-circle");
      $(ele[i]).find(".li-content").removeClass("bg-green-border");
      $(ele[i]).find(".cicle").addClass("bg-orange-circle");
      $(ele[i]).find(".li-content").addClass("bg-orange-border");
      $(ele[i]).siblings().find(".cicle").addClass("bg-green-circle");
      $(ele[i]).siblings().find(".li-content").addClass("bg-green-border");
      $(ele[i]).siblings().find(".cicle").removeClass("bg-orange-circle");
      $(ele[i]).siblings().find(".li-content").removeClass("bg-orange-border");
      i++;
      if (i == length) {
        i = 0;
      }
    }, 3000);
  }
}
