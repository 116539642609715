// 这是数说机构-六大交易所央企项目市场份额统计

import PieCentralProjectByMpsController from "./pie_central_project_by_mps_controller"; // board1 - 六大交易所央企项目市场份额统计饼图
import CentralGuquanProjectMarketController from "./central_guquan_project_market_controller"; // board1 - 央企股权转让项目市场份额统计
function setup(application){
  application.register("data-mps--board1--pie-central-project-by-mps", PieCentralProjectByMpsController)
  application.register("data-mps--board1--central-guquan-project-market", CentralGuquanProjectMarketController)
}

export {
  setup
}
