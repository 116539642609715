import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scrollBox"];

  connect() {
    this.scrollBoxTarget.scrollIntoView({ block: "nearest", inline: "nearest" })
  }

}
