import { Controller } from "@hotwired/stimulus";
import { Datepicker } from 'vanillajs-datepicker'
import zhCN from 'vanillajs-datepicker/locales/zh-CN';
Object.assign(Datepicker.locales, zhCN);
export default class extends Controller{
  static values = { formName:String, autoSubmit:Boolean }
  connect() {
    this.dateRangePicker = new Datepicker(this.element, {
      buttonClass: 'btn',
      language: 'zh-CN',
      clearBtn: true,
      autohide: true
    })

    if(this.autoSubmitValue){
      this.dateRangeInputEls = document.querySelectorAll('input');
      if ( this.dateRangeInputEls.length ) {
          this.dateRangeInputEls.forEach( ( dateInputEl ) => {
              dateInputEl.addEventListener( 'changeDate', ( event ) => {
                // const dateEl = event.target;
                document.getElementById(this.formNameValue).submit()
              } );
          } );
      }
    }

  }

}
