import { Controller } from '@hotwired/stimulus'
import Atropos from 'atropos';


export default class extends Controller {
  connect () {
    console.log('加载成功', this.element);

    const myAtropos = Atropos({
      el: this.element,
      // rest of parameters
    })
  }
}
