import { Mandarin } from "flatpickr/dist/l10n/zh.js"
import Flatpickr from 'stimulus-flatpickr'
import TimeShortcutPlugin from './time_shortcut_plugin'

export default class extends Flatpickr {
  static targets = ['custom']
  static values = {
    timeShortcut: Array,
    mode: { type: String, default: "single" }
  }

  initialize() {
    this.config = {
      locale: Mandarin,
      mode: this.modeValue,
      maxDate: 'today',
    }

    if(this.hasTimeShortcutValue) {
      Object.assign(this.config, {"plugins": [new TimeShortcutPlugin({timeOptions: this.timeShortcutValue})]})
    }
  }
  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
  }
}
