import ChartController from "../../chart_controller";
import { showFullScreen } from '../../../utils'
export default class extends ChartController {
  setOptions() {
    let legends = Object.keys(Object.entries(this.dataValue)[0][1])
    let xAxis = ['沪市主板','深市主板','创业板','科创板','新三板'] // 客户要求换这个顺序，所以写死
    let yAxis = []
    for (const key in this.dataValue) {
      const indexOfKey  = xAxis.indexOf(key)
      yAxis[indexOfKey] = this.dataValue[key]
    }
    const series = legends.map(legend => {
      return [legend, xAxis.map((x_axis, index) => yAxis[index][legend])]
    })
    let yAxisData = series[0][1].concat(series[1][1])
    const options = {
      title: {
        show: false,
        text: this.titleValue,
        top: "2%",
        left: "2%",
        textStyle: {
          color: '#fff'
        }
      },
      toolbox:{
        show: true,
        itemSize: 20,
        top: "2%",
        right: "2%",
        feature: {
          myFull: {
            show: true,
            title: '大图',
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAW9JREFUWEftlkFOwzAQRWd8B27QFgn2LV2khAMgVvQQcAjCihPAIdodFyDgRQn7INH2BtxhBhlqybUcRyR2qiK6yaKZ/Odv+88g7PiHO9aHvwVwKItLJjr2uUoC8nUyzvU7QR0YPC8yEOLGu61Et8vTcRYFQDlAgFc2AAIfAMCPMzEBbOHey+uRQJxtxMvvZ1cAtjgDfiJw2gmALU7MU0Rx3wmAS3w9OXnvy7en6ABV4upsRAfwiXcC0JfFIwKcA0Cp9lzZbt4KHVIoRPmRjOZRcmAgixkxZ7a4L5iCJmGTxvYPsF8OqKuGIO5Wk+FFk/121Ww5UHVVVKF1z+fLZDQNAbEFoPs5A+arZHimBepCpg1ILUBMcQXuBYgt7gVgpmtzmHDFaxvrnVFsngFjjHJmewjxSgdUQ9FjVKyVex3Y/FmqMcpeqQB+MLtZWyech/A3Y3VQgJ5cpIIg9bZPq58HBWj7sSb1+9WMmqywruYLOPX+Ibku0ksAAAAASUVORK5CYII=",
            onclick: (e) => {
              showFullScreen(e.getOption())
            },
          },
          myFullBack: {
            show: false,
            title: '缩小',
            icon: "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAaZJREFUWEfFlbtKA0EUhv/ZFPoMVhZhES3scjWraQQ7G9NZKtgK6WMv2Ap2dpo+rUk2kqx2YoRsIrY+hMyRiRlZ170Edmd2HmC+b/5zzhyGjA/Tzc/3R5szq/QmuVoFBNxg7J4TNaSENgEJB7AFYCwltAiEwUUZlAtEwVMTMPvOIQy24u4U7rxNHQdPT2DgfICwDuKnrlW+ERcvA09PwHZIvpwYNYmjI7rd33BBI59KD5gegQVkvAxcSQKeV/6OWtRnpyqBOZMRu5xYhWZmAgJMhOupVTwLk0grgddFzYM5nF+4u+WWuibsDVswcicArfkhBNad1gp1pQkk2aiplECZgGk7jwAqgQDClWsVz5PAI/+BvD3cM2A8RI4Q0fbEKr0kkYgswUbvqcINEikEHg5en9XKXWUC4mJz8HwA4p0giBaBuYTtiMVy5JfQIhAGFzLKBbxwBjQItAqwW5mEUgE/fFIrtn/KMTqWEtz4ys+q1ffUmzAMLkH53mg/x/CZdAQD/4E4eJLXxi4j3fA/CWQB/1cCIcGAtmy4tOOOLYEOoJ+R+Tr+Bkcm4yHTPcxWAAAAAElFTkSuQmCC",
            onclick: (e) => {
              showFullScreen(e.getOption())
            },
          }
        }
      },
      legend: {
        data: legends,
        bottom: 'left',
        left: '7%',
        itemHeight: 12,
        itemWidth: 12,
        icon:'rect',
        textStyle: {
          color: '#8DA7B3'
        }
      },
      xAxis: {
        type: 'category',
        data: xAxis,
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: '#18818B',
            width: 2
          }
        },
        axisLabel: {
          color: '#8DA7B3',
          formatter: (params) => {
            return params === 1 ? 0 : params;
          }
        }
      },
      yAxis: {
        type: 'log',
        name: '企业数(家)',
        logBase: Math.max(Math.ceil(Math.pow(Math.max(...yAxisData),0.25)), 2),
        nameTextStyle: {
          color: '#8DA7B3'
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: '#18818B',
            width: 2
          }
        },
        axisLabel: {
          color: '#8DA7B3',
          formatter: (params) => {
            return params === 1 ? 0 : params;
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#18818B',
            type: 'dashed'
          }
        }
      },
      series: series.map((item, index) => {
        return {
          name: item[0],
          type: 'bar',
          color: index === 0 ? '#FC5621' : '#C1E5F5',
          barMaxWidth: "25",
          label: {
            show: true,
            formatter: function (param) {
              return  param.data - 1
            },
            position: 'top',
            color: 'white',
            fontWeight: 'bold',
          },
          data: item[1].map((i) => {
            return i+1
          })
        }
      })
    };
    this.options = options
  }

}
