import { Controller } from "@hotwired/stimulus";
import * as echarts from 'echarts';

export default class extends Controller {
  connect() {
    const options = {
      title: {
        text: '并购事件热门行业',
        left: 'left'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'right'
      },
      series: [
        {
          type: 'pie',
          radius: '50%',
          data: [
            { value: 1048, name: '金融行业' },
            { value: 735, name: '其他行业' },
            { value: 580, name: '住宿和餐饮业' },
            { value: 484, name: '房地产业' },
            { value: 300, name: '建筑业' }
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    const w = this.element.clientWidth
    const h = this.element.clientHeight
    this.myChart = echarts.init(this.element, null, {
      width: w,
      height: h,
    });
    this.myChart.setOption(options);

    this.myChart.resize({
      width: this.element.clientWidth,
      height: this.element.clientHeight
    });

    window.addEventListener("resize", () => {
      this.myChart.resize({
        width: this.element.clientWidth,
        height: this.element.clientHeight
      });
    })
  }

  disconnect() {
    window.addEventListener("resize", () => {
      this.myChart.resize();
    })
  }
}
