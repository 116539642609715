import ChartController from "../../chart_controller";
export default class extends ChartController {
  setOptions() {
    let xAxis = []
    let seriesData = []
    for (const key in this.dataValue) {
      xAxis.push(key)
      const indexOfKey = xAxis.indexOf(key)
      seriesData[indexOfKey] = this.dataValue[key]
    }

    const options = {
      grid: {
        left: '30%',
      },
      yAxis: {
        type: 'category',
        data: xAxis,
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: '#18818B',
            width: 2
          }
        },
        axisLabel: {
          color: '#8DA7B3',
          interval:0
        }
      },
      xAxis: {
        position: 'top',
        type: 'value',
        name: '数量',
        nameTextStyle: {
          color: '#8DA7B3'
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: '#18818B',
            width: 2
          }
        },
        axisLabel: {
          color: '#8DA7B3',
          interval:0
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#18818B',
            type: 'dashed'
          }
        }
      },
      series: [
        {
          data: seriesData,
          type: 'bar',
          barMaxWidth: "25",
          color: '#F0982F',
          label: {
            show: true,
            position: 'right',
            color: 'white',
            fontWeight: 'bold',
            formatter: '{c}家'
          },
          itemStyle: {
            normal: {
                //每根柱子颜色设置
                color: function(params) {
                    let colorList = [
                        "#406A9C",
                        "#9E413E",
                        "#7F9A48"
                    ];
                    let index = params.dataIndex % colorList.length
                    return colorList[index];
                }
            }
        }
        }
      ]
    };
    this.options = options
  }
}
