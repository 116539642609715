import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [ "pageInput", "message" ]
  static values = { total: Number }

  validPageNumber(page_number){
    if(!page_number || page_number > this.totalValue) return false
    return page_number.match(/^[0-9]*$/)
  }

  send() {
    const page_number = this.pageInputTarget.value
    if(!this.validPageNumber(page_number)){
      this.messageTarget.textContent = '该页码不存在!'
      return
    } else {
      this.messageTarget.textContent = ''
      document.getElementById('page_form').submit()
    }
  }

  submit(){
    this.timer = null
    this.timer = setTimeout(() => {
      this.send()
    }, 1000);
  }

  connect() {
    this.pageInputTarget.addEventListener('keydown', (e) => {
      if(e.keyCode == 13){
        e.preventDefault() && e.stopPropagation()
        this.send()
      }
    });
  }
  disconnect(){
    this.timer = null
  }
}
